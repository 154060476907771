<template>
  <div class="d-flex flex-column flex-center flex-column-fluid h-100">
    <div class="d-flex flex-column flex-center text-center p-10">
      <div class="card card-flush w-lg-650px py-5">
        <div class="card-body py-15 py-lg-20">
          <h1 class="fw-bolder fs-2qx text-danger mb-4">
            {{
              $t(
                "BaseModelFields.CustomObjectFilterNotFoundTitle",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h1>
          <div class="fw-semibold fs-5 mb-7">
            {{
              $t(
                "BaseModelFields.CustomObjectFilterNotFoundDescription",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <div class="mb-11">
            <img
              :src="require('@/assets/img/illustrations/PageLayoutNone.png')"
              alt="SetXRM Cloud Solutions"
              class="mw-100 pointer-events-none"
            />
          </div>
          <div class="mb-0">
            <router-link :to="$appHomeUrl" class="btn btn-primary">
              <i class="bi-app-indicator bi"></i>
              {{
                $t(
                  "BaseModelFields.ReturnHome",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: "PageLayoutNone",
  mounted() {
    this.$root.changeDocumentTitle(
      this.$t("BaseModelFields.CustomObjectFilterNotFoundTitle"),
      {},
      { locale: this.$store.state.activeLang }
    );
    setCurrentPageBreadcrumbs(
      this.$t(
        "BaseModelFields.CustomObjectFilterNotFoundTitle",
        {},
        { locale: this.$store.state.activeLang }
      ),
      [
        {
          name: this.$t(
            "BaseModelFields.Help",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ]
    );
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      // store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      // store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });
  },
};
</script>
